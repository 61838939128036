<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 non-printable">
        <h3>Tender Report</h3>
           <table class="comicGreen">
             <thead>
               <tr>
                 <th>Qty</th>
                 <th>Tender</th>
                 <th>Total</th>
               </tr>
             </thead>
             <tfoot>
               <tr>
                 <td>{{tenders.reduce((a, b) => a + (b['qty'] || 0), 0)}}</td>
                 <td>Daily Total</td>
                 <td style="text-align:right;padding-right:1em;">{{currency(tenders.reduce((a, b) => a + (b['total'] || 0), 0))}}</td>
               </tr>
             </tfoot>
             <tbody>
               <tr v-for="(tender,index) in tenders" :key="index">
                 <td>{{tender.qty}}</td>
                 <td>{{(tender.title) ? tender.title : 'undefined' }}</td>
                 <td style="text-align:right;padding-right:1em;">{{currency(tender.total)}}</td>
               </tr>
             </tbody>
           </table>
      </div>
    </div>
    <!-- <Loader v-if="loading"/> -->
    <div class="footer-section">
      <div>
        <button class="icons-link-mobile text-center"  @click="sales()">
          <i class="fas fa-window-close footer-section-icon"></i>
          <div class="footer-section-text">Done</div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center" disabled @click="nop">
          <i class="footer-section-icon"></i>
          <div class="footer-section-text"></div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center"  disabled @click="nop">
          <i class="footer-section-icon"></i>
          <div class="footer-section-text"></div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center" disabled @click="nop">
          <i class="footer-section-icon"></i>
          <div class="footer-section-text"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex'
// import Loader from '@/components/Loader' 
import Currency from 'currency.js'
import GeneralService from '@/services/GeneralService'
import _ from 'lodash'; // todo: move this to root

export default {
  name: 'reports',
  components: {
    // Loader
  },
  data () {
    return {
      posConfig: {},
      tenders: [],
      showDescription : true,      // todo: fit this in if enabled
      loading         : true,
    }
  },
  watch: {
  },
  computed: {
  },
  async created () {
    this.posConfig = JSON.parse(localStorage.getItem( 'posConfig' ));   // Need this early
    this.taxRates = this.posConfig.taxRates;
    this.loading = false;

    let totals = JSON.parse(localStorage.getItem('dailyTotals'));
    this.tenders = totals.tender;

    console.log("totals", totals);
  },
  methods: {
    nop() {
      return;
    },
    sales() {
      //return this.$router.push({name: 'sale'});
      this.$router.go(-1);
    },
    currency(c) {
      return Currency(c);
    },
    async getReport(reportId) {
    },
    updateDailyTotals( transaction ) {
      let dt = JSON.parse(localStorage.getItem( 'dailyTotals' ));
      let tenderTotal = _.find( dt.tender , { id: transaction.tenderNumber});
      if( tenderTotal ) {
        tenderTotal.qty++;
        tenderTotal.total = Currency( tenderTotal.total ).add( transaction.total );
      } else {
        let tender = {
          qty: 1,
          total: transaction.total
        }
        dt.tender.push( tender );
      }

      localStorage.setItem( 'dailyTotals' , JSON.stringify(dt));
    }
  }
}
</script>

<style lang="scss">

  .show-hand {
    cursor:pointer;
  }


.button {
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
  .fa.selected {
    color: #fbeec1
  }
}

.button a {
  padding: 12px 48px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}
.footer-section{
  display: flex;
  justify-content: space-evenly;
  position: fixed !important;
  bottom: 0px !important;
  width: 100% !important;
  padding: 7px 0 3px 0 !important;
  background: #fff !important;
  z-index: 9999999 !important;
  left: 0;
  .icons-link-mobile {
    text-align: right;
    font-size: 14px;
    color: #659DBD;
    cursor: pointer;
    margin-bottom: 4px;
    background: none;
    border: none;
    outline: none !important;
    &:disabled {
      color: #adcbdc;
    }
  }

  .footer-section-icon{
    font-size: 20px;
  }
  .footer-section-text{
    //font-size: 7px;
    font-size:1em;
  }
}
.footer-section {
    box-shadow: 0px 0px 4px rgba(0,0,0,.2);
}
  table.comicGreen {
  font-family: "Comic Sans MS", cursive, sans-serif;
  border: 2px solid #4F7849;
  background-color: #EEEEEE;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
table.comicGreen td, table.comicGreen th {
  border: 1px solid #4F7849;
  padding: 3px 2px;
}
table.comicGreen tbody td {
  font-size: 19px;
  font-weight: bold;
  color: #4F7849;
}
table.comicGreen tr:nth-child(even) {
  background: #CEE0CC;
}
table.comicGreen tfoot {
  font-size: 21px;
  font-weight: bold;
  color: #FFFFFF;
  background: #4F7849;
  background: -moz-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: -webkit-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: linear-gradient(to bottom, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  border-top: 1px solid #444444;
}
table.comicGreen tfoot td {
  font-size: 21px;
}
</style>
